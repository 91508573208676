<template>
    <div class="box-white loginCard" style="width: 500px">
        <div class="img">
            <img src="../assets/media/img/logo.png" alt="">
        </div>
        <div class="header">
            <p v-if="this.valuePage">Giriş Yap</p>
            <p v-else>Kayıt Ol</p>
        </div>
        <div class="content my-3">
            <div class="row" v-if="this.valuePage" @keyup.enter="login()">
                <div class="col-12 my-3">
                    <div class="input-box">
                        <input type="text" v-model="this.auth.email" required>
                        <label>Kullanıcı adı</label>
                    </div>
                </div>
                <div class="col-12 my-3">
                    <div class="input-box">
                        <input type="password" v-model="this.auth.pass" required>
                        <label>Şifre</label>
                    </div>
                </div>
            </div>
            <div v-else class="row" @keyup.enter="register()">
                <div class="col-md-6 my-3">
                    <div class="input-box">
                        <input type="text" v-model="this.user.userName" required>
                        <label>Ad</label>
                    </div>
                </div>
                <div class="col-md-6 my-3">
                    <div class="input-box">
                        <input type="text" v-model="this.user.userSurname" required>
                        <label>Soyad</label>
                    </div>
                </div>
                <div class="col-12 my-3">
                    <div class="input-box">
                        <input type="text" v-model="this.user.userMail" required>
                        <label>Kullanıcı adı</label>
                    </div>
                </div>
                <div class="col-md-6 my-3">
                    <div class="input-box">
                        <input type="password" v-model="this.passControl1" required>
                        <label>Şifre</label>
                    </div>
                </div>
                <div class="col-md-6 my-3">
                    <div class="input-box">
                        <input type="password" v-model="this.passControl2" required>
                        <label>Tekrar Şifre</label>
                    </div>
                </div>
                <div class="col-12">
                    
                </div>
            </div>

        </div>
        <div class="buttons">
            <div class="ps-3">
                <span v-if="this.valuePage" @click="this.valuePage = false">Hesabım yok</span>
                <span v-else @click="this.valuePage = true">Hesabım ile giriş yap</span>
            </div>
            <div>
                <button class="btn bg-info float-end mx-1" @click="register()" v-if="!this.valuePage" :disabled="btnDelay">Kayıt Ol</button>
                <button class="btn bg-success text-white float-end mx-1" @click="login()" v-if="this.valuePage" :disabled="btnDelay">Giriş</button>
            </div>
        </div>
    </div>
    <div class="popup" v-if="this.$route.query.process != null">
        <div class="background-area" @click="this.$router.replace({ query: null })"></div>
        <kvkkPage v-if="this.$route.query.process == 'kvkk'"/>
    </div>
</template>

<style scoped>
.loginCard {
    position: relative;
    border-radius: .5em;
    padding: 1em;
}

.loginCard .img {
    height: 100px;
}

.loginCard .img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.loginCard .header {
    width: 100%;
    text-align: center;
    padding: 1em;
    font-size: 1.5em;
}

.loginCard .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 1em;
}
</style>

<script>
import kvkkPage from './_KVKK.vue'
import axios from 'axios';
import { mapMutations } from "vuex";

export default {
    name: 'loginPage',
    components: {
        kvkkPage
    },
    data() {
        return {
            valuePage: true,
            btnDelay: false,
            options: {
                kvkk: false,
            },
            auth: {
                email: '',
                pass: ''
            },
            passControl1: '',
            passControl2: '',
            user: {
                userName: '',
                userSurname: '',
                userMail: '',
                userPhone: '',
                userPassword: ''
            },
        }
    },
    methods: {
        ...mapMutations([
            "logout"
        ]),
        async login() {
            this.btnDelay = true
            setTimeout(() => {
                this.btnDelay = false
            }, 1000);
            try {
                var req = await axios.get(this.$store.state.axiosURL + '/users/getByToken', { params: { userMail: this.auth.email, userPassword: await this.$crypto.sha256(this.auth.pass).toString() } })
                if (req.status == 200) {
                    localStorage.token = req.data
                    req = await axios.get(this.$store.state.axiosURL + '/users/checkByToken', { params: { token: localStorage.token } })
                    if (req.status == 200) {
                        this.$router.push('/myaccount')
                        this.$router.go()
                    }
                    else this.$alertBox.success(req.data)
                }
                else this.$alertBox.success(req.data)
            } catch (error) {
                if (error.response) this.$alertBox.warning(error.response.data.message)
                else console.error(error.message)
            }
        },
        async register() {
            this.btnDelay = true
            setTimeout(() => {
                this.btnDelay = false
            }, 1000);
            this.$swal({
                title: 'Bilgilerimi kaydet',
                showCancelButton: false,
                showDenyButton: true,
                confirmButtonText: 'İşlemi onayla',
                denyButtonText: `Vazgeç`,
                timer: this.$store.state.toastrAlert.alertduration,
                timerProgressBar: true,
            })
                .then(async (result) => {
                    if (result.isConfirmed) {
                        if (this.user.userName.length < 3 || this.user.userSurname.length < 3) this.$alertBox.warning('Kullanıcı adı veya soyadı bilgileri 3 karakterden kısa olamaz')
                        else if (this.passControl1 != this.passControl2) this.$alertBox.warning('Şifreler uyuşmuyor.')
                        else if (this.user.userPassword.length < 8) this.$alertBox.warning('Şifreniz en az 8 karakterden oluşmalıdır.')
                        else {
                            this.user.userPassword = await this.$crypto.sha256(this.user.userPassword).toString()
                            await this.$axios.post({ uri: '/users/add', valueData: this.user })
                            this.auth.email = this.user.userMail
                            this.valuePage = true
                        }
                    }
                })
        }
    },
    async mounted() {
        if (localStorage.token != undefined) this.$router.replace({ query: null })
    },
    computed: {
    },
    watch: {
        passControl1() {
            if (this.passControl1 == this.passControl2) this.user.userPassword = this.passControl1
            else this.user.userPassword = ''
        },
        passControl2() {
            if (this.passControl1 == this.passControl2) this.user.userPassword = this.passControl2
            else this.user.userPassword = ''
        }
    }
}
</script>
