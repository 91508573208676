<template>
  <div class="spinners" v-if="spinners">
    <atom-spinner :animation-duration="1000" :size="100" color="#ff1d5e" />
  </div>
  <header v-if="this.auth.authority">
    <ul>
      <li @click="collapse"><i class="fas fa-bars btn-aside zoom"></i></li>
    </ul>
    <div v-if="this.selectedMachine != undefined">
      <span>{{ this.$store.state.machineData.filter(x => x.machineID ==
        this.selectedMachine)[0].machineChangeDate.slice(0, 19) }}</span>
    </div>
    <ul>
      <li id="loginBtn" v-if="this.auth.authority" @click="exitAccount()">
        <i class="fas fa-sign-in-alt"></i>
      </li>
      <li id="fsBtn" @click="fullScreen">
        <i class="fas fa-expand-arrows-alt zoom"></i>
      </li>
    </ul>
  </header>

  <nav v-if="this.auth.authority">
    <div class="logo-details big">
      <div id="logo-small" class="logo d-none">
        <img src="@/assets/media/img/logo.png" alt="" />
      </div>
      <div id="logo-big" class="logo">
        <img src="@/assets/media/img/logo2.png" alt="" />
      </div>
      <span class="logo-name line-limit-1">..\BilirArsiv</span>
    </div>
    <ul class="nav-list">
      <li class="nav-item" v-if="this.auth.authList.includes('user')">
        <a class="nav-link">
          <i class="fas fa-users"></i>
          <router-link class="nav-title fw-bold" to="/panel/users">Kullanıcılar</router-link>
        </a>
      </li>
      <li class="nav-item" v-if="this.auth.role == 'admin'">
        <a class="nav-link">
          <i class="fas fa-database"></i>
          <router-link class="nav-title fw-bold" to="/panel/archives">Veriler</router-link>
        </a>
      </li>
      <li class="nav-item" v-if="this.auth.authList.includes('token')">
        <a class="nav-link">
          <i class="fas fa-key"></i>
          <router-link class="nav-title fw-bold" to="/panel/tokens">Lisanslar</router-link>
        </a>
      </li>
      <li class="nav-item" v-if="this.auth.authList.includes('machine')">
        <a class="nav-link" @click="dropdown($event)">
          <i class="fas fa-bars"></i>
          <i class="fas fa-chevron-down arrow"></i>
          <span class="nav-title fw-bold">Makine takip</span>
        </a>
        <ul class="sub-list">
          <li class="nav-item">
            <a class="nav-link">
              <i class="fas fa-bars"></i>
              <router-link class="nav-title fw-bold" to="/panel/machines">Makine listesi</router-link>
            </a>
          </li>
          <li class="nav-item" v-if="this.auth.authorization.filter(x => x.main == 'machine')[0].sub.includes('*') || this.auth.authorization.filter(x => x.main == 'machine')[0].sub.includes('map')">
            <a class="nav-link">
              <i class="fas fa-bars"></i>
              <router-link class="nav-title fw-bold" to="/panel/map">Harita</router-link>
            </a>
          </li>
          <li class="nav-item" v-if="this.auth.authorization.filter(x => x.main == 'machine')[0].sub.includes('*') || this.auth.authorization.filter(x => x.main == 'machine')[0].sub.includes('version')">
            <a class="nav-link">
              <i class="fas fa-bars"></i>
              <router-link class="nav-title fw-bold" to="/panel/path/versions">Sürüm kontrol</router-link>
            </a>
          </li>
          <li class="nav-item" v-if="this.auth.authorization.filter(x => x.main == 'machine')[0].sub.includes('*') || this.auth.authorization.filter(x => x.main == 'machine')[0].sub.includes('memory')">
            <a class="nav-link">
              <i class="fas fa-bars"></i>
              <router-link class="nav-title fw-bold" to="/panel/path/memory">Hafıza kontrol</router-link>
            </a>
          </li>
          <li class="nav-item" v-if="this.auth.authorization.filter(x => x.main == 'machine')[0].sub.includes('*') || this.auth.authorization.filter(x => x.main == 'machine')[0].sub.includes('memory')">
            <a class="nav-link">
              <i class="fas fa-bars"></i>
              <router-link class="nav-title fw-bold" to="/panel/path/systems">Sistemler</router-link>
            </a>
          </li>
          <li class="nav-item" v-if="this.auth.authorization.filter(x => x.main == 'machine')[0].sub.includes('*') || this.auth.authorization.filter(x => x.main == 'machine')[0].sub.includes('error')">
            <a class="nav-link">
              <i class="fas fa-bars"></i>
              <router-link class="nav-title fw-bold" to="/panel/path/errors">Uyarılar</router-link>
            </a>
          </li>
          <li class="nav-item" v-if="this.auth.authorization.filter(x => x.main == 'machine')[0].sub.includes('*') || this.auth.authorization.filter(x => x.main == 'machine')[0].sub.includes('service')">
            <a class="nav-link">
              <i class="fas fa-bars"></i>
              <router-link class="nav-title fw-bold" to="/panel/path/services">Servisler</router-link>
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-item" v-if="false">
        <a class="nav-link">
          <i class="fas fa-bars"></i>
          <router-link class="nav-title fw-bold" to="/panel/path/technicalDetails">Teknik kontrol</router-link>
        </a>
      </li>
      <li class="nav-item" v-if="this.auth.authorization.filter(x => x.main == 'order')[0]?.sub.includes('*') || this.auth.authorization.filter(x => x.main == 'order')[0]?.sub.includes('service')">
        <a class="nav-link">
          <i class="fas fa-bars"></i>
          <router-link class="nav-title fw-bold" to="/panel/orders">Emirler</router-link>
        </a>
      </li>
      <li class="nav-item" v-if="this.auth.authorization.filter(x => x.main == 'technical')[0]?.sub.includes('*') || this.auth.authorization.filter(x => x.main == 'technical')[0]?.sub.includes('view')">
        <a class="nav-link">
          <i class="fas fa-bars"></i>
          <router-link class="nav-title fw-bold" to="/panel/technical">Teknik servis</router-link>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <i class="fas fa-bars"></i>
          <router-link class="nav-title fw-bold" to="/myaccount">Hesabım</router-link>
        </a>
      </li>
      <div v-if="myMachines">
        <li class="nav-item">
          <a class="nav-link">
            <i class="fas fa-home"></i>
            <router-link class="nav-title fw-bold" to="/">Anasayfa</router-link>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link">
            <i class="fas fa-paw"></i>
            <router-link class="nav-title fw-bold" to="/calf">Buzağılar</router-link>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link">
            <i class="fas fa-chart-line"></i>
            <router-link class="nav-title fw-bold" to="/calf/consumption">Tüketim Miktarları</router-link>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link">
            <i class="fas fa-chart-line"></i>
            <router-link class="nav-title fw-bold" to="/visits">Ziyaret Kayıtları</router-link>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link">
            <i class="fas fa-exclamation-circle"></i>
            <router-link class="nav-title fw-bold" to="/exp">Gelişim Takibi</router-link>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link">
            <i class="fas fa-exclamation-circle"></i>
            <router-link class="nav-title fw-bold" to="/errors">Uyarılar</router-link>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link">
            <i class="fas fa-exclamation-circle"></i>
            <router-link class="nav-title fw-bold" to="/calf/archives">Arşivler</router-link>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link">
            <i class="fas fa-route"></i>
            <router-link class="nav-title fw-bold" to="/services">Servis takip listesi</router-link>
          </a>
        </li>
        <hr>
      </div>
      <li class="nav-item" v-if="false">
        <a class="nav-link" @click="dropdown($event)">
          <i class="fas fa-home"></i>
          <i class="fas fa-chevron-down arrow"></i>
          <span class="nav-title fw-bold">Sub Item</span>
        </a>
        <ul class="sub-list">
          <li class="nav-item">
            <a class="nav-link">
              <i class="fas fa-chart-line"></i>
              <span class="nav-title fw-bold" to="/panel/baskets">Item 1</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link">
              <i class="fas fa-chart-line"></i>
              <span class="nav-title fw-bold" to="/panel/baskets">Item 2</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
    <div class="nav-profil">
      <div class="profil-img">
        <img src="@/assets/media/img/logo.png" alt="" @click="userSettings" />
      </div>
      <p class="profil-name line-limit">{{ this.auth.userMail }}</p>
    </div>
  </nav>

  <main class="container-xxxl" v-if="this.auth.authority">
    <div class="content">
      <div class="main-header">
        <p class="page-name">{{ this.breadcrumb[this.breadcrumb.length - 1] }}</p>
        <ul class="breadcrumb">
          <li><a href="/">AnaMenü</a></li>
          <li v-for="(item, index) in this.breadcrumb" :key="index">{{ item }}</li>
        </ul>
      </div>
      <div style="height: 50px;" v-if="this.$route.path.split('/')[1] != 'panel'">
        <div class="input-box w250 float-end">
          <div class="input-area-single">
            <select class="input" name="calfTypeID" id="calfType" v-model="selectedMachine">
              <option :value="undefined" disabled>Seçilmemiş</option>
              <option v-for="(item, index) in myMachines" :key="index" :value="item.machineID">
                <span v-if="item.machineSubID < 100">{{ item.machineCode + '-' + '00' + item.machineSubID + '-' +
                  item.machineCity }}</span>
                <span v-else>{{ item.machineCode + '-' + '0' + item.machineSubID + '-' + item.machineCity }}</span>
              </option>
            </select>
          </div>
        </div>
      </div>
      <router-view v-if="this.$route.path.split('/')[1] == 'panel'" @breadcrumb="breadcrumb = $event" />
      <router-view v-else @breadcrumb="breadcrumb = $event" :selectedMachine="this.selectedMachine" />
    </div>
    <div class="perde" v-if="navOptionsBarShow" @click="navOptionsBar0()"></div>

    <div id="navOptionsBar" class="nav-options-bar" v-if="services != undefined">
      <div class="door-handle" @click="navOptionsBar0()">
        <i class="fas fa-cog bg-transparent"></i>
      </div>
      <div class="content">
        <div class="nob-header">
          <p class="title mb-2 fw-bold">SİSTEM YAPILANDIRMA</p>
        </div>
        <ul class="nob-services">
          <div class="row m-0">
            <div class="text-center">
              <p class="fw-bold">Servisler</p>
            </div>
            <div class="col-12">
              <li class="service text-center active" @click="this.serviceReboot({ service: 'UPGRADE' })">
                <span>Upgrade</span>
              </li>
            </div>
            <div class="col-12">
              <li class="service text-center active" @click="this.serviceReboot({ service: 'CRM' })">
                <span>CRM</span>
              </li>
            </div>
            <div v-for="(item, index) in 3" :key="index" class="col-4">
              <li class="service text-center" @click="this.serviceReboot({ service: services[index].name })" :class="{
                active: services[index].counter >= 5,
                warn: services[index].counter < 5 && services[index].counter > 0,
              }">
                <span>{{ services[index].name }}</span>
              </li>
            </div>
            <div v-for="(item, index) in this.stationCount" :key="index"
              :class="{ 'offset-3': this.stationCount == 2 && index == 0 }" class="col-3">
              <li class="service text-center" @click="this.serviceReboot({ service: services[index + 3].title })"
                :class="{
                  active: services[index + 3].counter >= 5,
                  warn: services[index].counter < 5 && services[index].counter > 0,
                }">
                <span>{{ services[index + 3].title }}</span>
              </li>
            </div>
            <div class="text-center">
              <hr />
              <p class="title mb-2 fw-bold">Makina Hızlı Erişim</p>
            </div>
            <div class="col-12 mt-2">
              <button type="button" class="btn btn-sm btn-outline-success w-100" :disabled="!this.dataFastAccess.reboot"
                :class="{ 'btn-outline-danger': !this.dataFastAccess.reboot }"
                @click="fastAccess('reboot', 'Yeniden başlatma')">
                Yeniden Başlat
              </button>
            </div>
            <div class="col-6 mt-2">
              <button type="button" class="btn btn-sm btn-outline-warning w-100" :disabled="!this.dataFastAccess.reboot"
                :class="{ 'btn-outline-danger': !this.dataFastAccess.reboot }"
                @click="fastAccess('automatic', 'otomatik moda alma')">
                Otomatik
              </button>
            </div>
            <div class="col-6 mt-2">
              <button type="button" class="btn btn-sm btn-outline-warning w-100" :disabled="!this.dataFastAccess.reboot"
                :class="{ 'btn-outline-danger': !this.dataFastAccess.reboot }"
                @click="fastAccess('manual', 'Manual moda alma')">
                Manual
              </button>
            </div>
            <div class="offset-3 col-6 mt-2" v-if="controlPLC.errorCode >= 100">
              <button type="button" class="btn btn-sm btn-outline-success w-100"
                @click="fastAccess('errorReset', 'Hata sıfırlama')">
                Hata Gider
              </button>
            </div>
            <div class="col-12 mt-2">
              <button type="button" class="btn btn-sm btn-outline-success w-100" :disabled="!controlPLC.washingStatus"
                @click="fastAccess('washingNormal', 'Normal yıkama')"
                :class="{ 'btn-outline-danger': !controlPLC.washingStatus }">
                Normal Yıkama
              </button>
            </div>
            <div class="col-12">
              <div class="text-center mt-3">
                <p class="title mb-2 fw-bold">Ek kontroller</p>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-6">
                  <span>Sıvı seviye</span>
                </div>
                <div class="col-6">
                  <div class="status-manager">
                    <span class="status" :class="{ 'bg-success': this.controlPLC.liquidLevel1 }"></span>
                    <span class="status" :class="{ 'bg-success': this.controlPLC.liquidLevel2 }"></span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ul class="fastAccessList" v-if="this.controlPLC.errorCode != undefined">
                <li class="fastAccessList-item bg-info" v-if="this.controlPLC.tempBoiler < 2000">
                  <i class="fas fa-temperature-low"></i>
                  <span>Kazan: {{ (this.controlPLC.tempBoiler / 10).toFixed(1) }}°</span>
                </li>
                <li class="fastAccessList-item bg-info" v-if="this.controlPLC.tempMixer < 2000">
                  <i class="fas fa-temperature-low"></i>
                  <span>Mikser: {{ (this.controlPLC.tempMixer / 10).toFixed(1) }}°</span>
                </li>
                <li class="fastAccessList-item bg-info" v-if="this.controlPLC.tempOutdoor < 2000">
                  <i class="fas fa-temperature-low"></i>
                  <span>Hava: {{ (this.controlPLC.tempOutdoor / 10).toFixed(1) }}°</span>
                </li>
              </ul>
            </div>
            <div>
              <hr />
            </div>
          </div>
        </ul>
      </div>
    </div>
  </main>

  <footer v-if="this.auth.authority">
    <div class="content">
      <p>© {{ this.$moment.date().slice(0, 4) }}</p>
      <p>v1.10.0</p>
    </div>
  </footer>
  <div class="popup" v-if="this.$route.query.login != null">
    <div class="background-area" @click="this.$router.replace({ query: null })"></div>
  </div>
  <div class="specialLoginArea" v-if="!this.auth.authority">
    <loginPage />
  </div>
</template>

<style scoped>
.error-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 110;
  width: 100vw;
  padding: 1em;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.error-alert span {
  max-width: 75%;
}

.fastAccessList {
  padding: 0;
}

.fastAccessList .fastAccessList-item:first-child {
  margin-top: 3em;
}

.fastAccessList .fastAccessList-item {
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 1em;
  border-radius: 0.5em;
  padding: 0.5em;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.fastAccessList .fastAccessList-item i {
  padding: 0 0.5em;
}

.perde {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.3);
}

#technicalNote {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
}

#technicalNote p {
  margin-bottom: 200px;
  font-size: 1.5em;
  max-width: 500px;
  text-align: center;
  font-weight: bold;
}

input {
  width: 100%;
  height: 100px;
  padding: 20px;
  font-size: 20px;
  border: none;
  box-sizing: border-box;
}

.simple-keyboard {
  max-width: 850px;
}

.specialLoginArea {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<script>
import axios from 'axios';
import { mapMutations } from "vuex";
import { AtomSpinner } from "epic-spinners";
import loginPage from '@/views/login.vue'

export default {
  components: {
    AtomSpinner,
    loginPage
  },
  data() {
    return {
      spinners: true,
      navOptionsBarShow: false,
      breadcrumb: ['Anamenü'],
      selectedMachine: undefined,
      dataFastAccess: {
        reboot: true,
        errorReset: true,
      },
    };
  },
  methods: {
    ...mapMutations([
      "collapse",
      "fullScreen",
      "navOptionsBar",
      "logout"
    ]),
    dropdown(event) {
      event.target.parentElement.parentElement.classList.toggle("active")
      event.target.parentElement.parentElement.classList.toggle("open")
    },
    navOptionsBar0() {
      this.navOptionsBar();
      this.navOptionsBarShow = !this.navOptionsBarShow;
    },
    async checkByToken() {
      if (localStorage.token != undefined) {
        try {
          var req = await axios.get(this.$store.state.axiosURL + '/users/checkByToken', { params: { token: localStorage.token } })
          if (req.status == 200) {
            this.auth.authority = true
            this.auth.userID = req.data.userID
            this.auth.role = req.data.userRole
            this.auth.userMail = req.data.userMail
            this.auth.authorization = req.data.userAuthority
            this.auth.authList = req.data.authList
          }
          else this.$alertBox.success(req.data)
        } catch (error) {
          if (error.response) this.$alertBox.warning(error.response.data)
          else console.error(error.message)
          this.logout()
        }
      }
      else this.auth.authority = false
      this.spinners = false;
    },
    async serviceReboot({ service }) {
      this.$swal({
        title: "Servisi yeniden başlatmak istediğinize emin misiniz?",
        showCancelButton: false,
        showDenyButton: true,
        confirmButtonText: "İşlemi onayla",
        denyButtonText: `Vazgeç`,
        timer: this.$store.state.toastrAlert.alertduration,
        timerProgressBar: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var item = {
              userID: this.userID,
              machineID: this.selectedMachine,
              orderType: 0,
              orderService: this.orderBox.services?.filter(x => x.serviceTag == service)[0].id
          }
          await this.$axios.post({ uri: '/orders/fastAccess/', valueData: item })
        }
        else this.$alertBox.cancel()
      });
    },
    async fastAccess(type) {
      this.$swal({
        title: type + " işlemi yapmak istediğinize emin misiniz?",
        showCancelButton: false,
        showDenyButton: true,
        confirmButtonText: "İşlemi onayla",
        denyButtonText: `Vazgeç`,
        timer: this.$store.state.toastrAlert.alertduration,
        timerProgressBar: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var item = {
              userID: this.userID,
              machineID: this.selectedMachine,
              orderType: 90,
              orderService: 1,
              orderContent: 'W-M-0-0'
          }
          if (type == "reboot") item.orderContent = 'W-M-43-1-0'
          else if (type == "manual") item.orderContent = 'W-M-0-1'
          else if (type == "automatic") item.orderContent = 'W-M-0-0'
          else if (type == "errorReset") item.orderContent = 'W-D-98-1'
          else if (type == "washingNormal") item.orderContent = 'W-M-345-1'
          await this.$axios.post({ uri: '/orders/fastAccess/', valueData: item })
        } else this.$alertBox.cancel()
      });
    },
    exitAccount() {
      this.$swal({
        title: 'Çıkış yapmak istediğinize emin misiniz',
        showCancelButton: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: `Vazgeç`,
        timer: this.$store.state.toastrAlert.alertduration,
        timerProgressBar: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.logout()
          }
        })
    },
    async loop() {
      if (this.selectedMachine == undefined) {
        if (this.userID != undefined) {
          this.$store.state.machineData = await this.$axios.get({ uri: '/machines/getMyMachines/' + this.userID })
          if (this.$store.state.machineData.length > 0) this.selectedMachine = this.$store.state.machineData[0].machineID
        }

      }
      else if (this.$store.state.machineData.length > 0) {
        this.$store.state.serviceData = this.$store.state.machineData.filter(x => x.machineID == this.selectedMachine)[0].machineData[0].services.services
      }
      if (this.userID != undefined) this.$store.state.machineData = await this.$axios.get({ uri: '/machines/getMyMachines/' + this.userID })
    }
  },
  async mounted() {
    await this.checkByToken()
    if (window.innerWidth < 992) this.collapse()
    if (this.userID != undefined) this.$store.state.machineData = await this.$axios.get({ uri: '/machines/getMyMachines/' + this.userID })
    clearInterval()
    this.loop()
    setInterval(async () => {
      this.loop()
    }, 1000)
    this.$store.state.orderBox = await this.$axios.get({ uri: '/orders/getAllOrders' })
  },
  computed: {
    auth() {
      return this.$store.state.calibrations["auth"]
    },
    myMachines() {
      if (this.selectedMachine != undefined) {
        return this.$store.state.machineData
      }
      else return undefined
    },
    services() {
      if (this.selectedMachine != undefined) {
        return this.$store.state.machineData.filter(x => x.machineID == this.selectedMachine)[0].machineData[0].services.services
      }
      else return undefined
    },
    controlPLC() {
      if (this.selectedMachine != undefined) {
        return this.$store.state.machineData.filter(x => x.machineID == this.selectedMachine)[0].machineData[0].plc
      }
      else return undefined
    },
    stationCount() {
      return this.$store.state.machineData.filter(x => x.machineID == this.selectedMachine)[0].machinestationCount
    },
    orderBox() {
      return this.$store.state.orderBox
    },
    userID() {
      return this.$store.state.calibrations.auth.userID
    }
  }
};
</script>
