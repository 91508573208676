import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/:catchAll(.*)', redirect: '/', },

  { path: '/panel/users', name: "user", component: () => import('@/views/panel/users/users.vue') },
  { path: '/panel/tokens', name: "token", component: () => import('@/views/panel/tokens/tokens.vue') },

  { path: '/panel/orders', name: "order", component: () => import('@/views/panel/orders/orders.vue') },
  { path: '/panel/machines', name: "machine", component: () => import('@/views/panel/machines/machines.vue') },
  { path: '/panel/map', name: "map", component: () => import('@/views/panel/machines/map.vue') },
  
  { path: '/panel/archives', name: "archive", component: () => import('@/views/panel/archives/archives.vue') },
  { path: '/panel/archives/details/:id', name: "archiveDetails", component: () => import('@/views/panel/archives/archiveDetails.vue') },
  
  { path: '/panel/path/versions', name: "machine-versions", component: () => import('@/views/panel/machines/pathControl/versions.vue') },
  { path: '/panel/path/errors', name: "machine-path-errors", component: () => import('@/views/panel/machines/pathControl/errors.vue') },
  { path: '/panel/path/memory', name: "machine-path-memory", component: () => import('@/views/panel/machines/pathControl/memory.vue') },
  { path: '/panel/path/services', name: "machine-path-services", component: () => import('@/views/panel/machines/pathControl/services.vue') },
  { path: '/panel/path/systems', name: "machine-path-systems", component: () => import('@/views/panel/machines/pathControl/systems.vue') },
  { path: '/panel/path/technicalDetails', name: "machine-path-technical", component: () => import('@/views/panel/machines/technicalDetails.vue') },

  { path: '/panel/total/errors', name: "machine-total-errors", component: () => import('@/views/panel/totalPage/totalErrors.vue') },
  { path: '/panel/technical', name: "technical", component: () => import('@/views/panel/technicalLogs/technicalLogs.vue') },

  { path: '/', component: () => import('@/views/dashboard/dashboard.vue') },
  { path: '/myaccount', name: "myprofil", component: () => import('@/views/dashboard/myAccount/profil.vue') },
  { path: '/calf', name: "calf", component: () => import('@/views/dashboard/calfs/calfList.vue') },
  { path: '/calf/details', name: "calfDetails", component: () => import('@/views/dashboard/calfs/calfDetails.vue') },
  { path: '/calf/consumption', name: "calfConsumption", component: () => import('@/views/dashboard/calfs/calfConsumption.vue') },
  { path: '/calf/archives', name: "calfArchive", component: () => import('@/views/dashboard/archiveLog.vue') },
  { path: '/errors', component: () => import('@/views/dashboard/errorLog.vue') },
  { path: '/services', component: () => import('@/views/dashboard/serviceLog.vue') },
  { path: '/visits', component: () => import('@/views/dashboard/feedingLog.vue') },
  { path: '/exp', component: () => import('@/views/dashboard/calfs/experience.vue') },


  { path: '/default', component: () => import('@/views/_defaultPagePagination.vue') }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  next();
});

export default router
